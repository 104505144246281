import React, { useEffect, useState } from 'react';
import SiteLayout from '../../components/layout/SiteLayout';
import { graphql } from 'gatsby';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import RelatedFeatures from './related-features';
import Link from 'gatsby-link';
import { GatsbyImage } from "gatsby-plugin-image";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { hostname } from '../../utils/constants';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function FeatureDetails(props) {
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  let ldJsonHome = "Domů";
  let ldJsonLevel1Route ="/funkce/";
  let ldJsonLevel1Name = "Funkce";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + "/" + props?.pageContext?.language + ldJsonLevel1Route,
          "name": ldJsonLevel1Name
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": hostname + props?.pageContext?.url,
          "name": props?.pageContext?.name,
          "image": hostname + props?.pageContext?.metaImage
        }
      },
    ]
  };

  useEffect(() => {
    if (props.data?.mdx?.exports?.carouselImages) {
      const localImages = [];
      for (let image of props.data?.mdx?.exports?.carouselImages) {
        localImages.push({ fluid: image.name.childImageSharp.gatsbyImageData, fixed: image.name.childImageSharp.gatsbyImageData.images.fallback.src, alt: image.alt});
      }

      setImages(localImages);
    }
  }, [props.data]);

  return (
    <SiteLayout relatedPages={props?.data?.mdx?.fields?.relatedPages} lang={props?.pageContext?.language} canonical={props?.data?.mdx?.fields?.canonical} title={props?.data?.mdx?.frontmatter?.title} seo={{metaTitle: props.data?.mdx?.frontmatter?.metaTitle, metaDescription: props.data?.mdx?.frontmatter?.metaDescription, metaImage: props.data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: props.data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: props.data?.mdx?.frontmatter?.twitterCard, ldJson: props.data?.mdx?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: props.data?.mdx?.frontmatter?.metaKeywords, slug: props.data?.mdx?.fields?.slug.replace("/posts", "")}}>
      <div>
        <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96"}>
          <div className={"w-full"}>
            <p className={"text-right w-full text-sm text-gray-500 mt-5 capitalize"}><Link to={"/"}>Domů</Link> > <Link to={"/funkce/"}>Funkce</Link> > {props.data?.mdx?.frontmatter?.path?.replaceAll('-', ' ')}</p>
          </div>
          <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light py-20"}>{props?.data?.mdx?.frontmatter?.title}</h1>
        </div>
        <div className={"w-full h-full flex flex-col items-center md:flex-row px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96 mb-20"}>
          <div className={"w-full md:w-1/2 flex flex-col mr-0 mb-5 mt-5 md:mr-5 md:mb-0 md:mt-0"}>
            <h2 className={"text-lg md:text-xl lg:text-2xl pb-5 text-center text-primary-default font-light"}>{props?.data?.mdx?.frontmatter?.carouselTitle}</h2>
            {props?.data?.mdx?.frontmatter?.carouselDescription.map((description, index) => <p className={"text-sm text-gray-500"} key={index}>{description}</p>)}
          </div>
          <div className={"w-full md:w-1/2"}>
            {images && images.length &&
              <Carousel containerClass="carousel-container" responsive={responsive} infinite autoPlay autoPlaySpeed={4000} showDots>
                {images.map((image, index) => <div className={"cursor-pointer"} key={index} onClick={() => { setImageIndex(index); setModalIsOpen(true); }}><GatsbyImage alt={image.alt} image={image.fluid} /></div>)}
              </Carousel>
            }
            {modalIsOpen && images && images.length && (
              <Lightbox
                animationDisabled={true}
                enableZoom={false}
                mainSrc={images[imageIndex].fixed}
                nextSrc={images[(imageIndex + 1) % images.length].fixed}
                prevSrc={images[(imageIndex + images.length - 1) % images.length].fixed}
                onCloseRequest={() => setModalIsOpen(false)}
                onMovePrevRequest={() =>
                  setImageIndex((imageIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                  setImageIndex((imageIndex + 1) % images.length)
                }
              />
            )}
          </div>
        </div>
        <div className={"flex flex-row flex-wrap justify-center w-full px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96"}>
          {props?.data?.mdx?.frontmatter?.chipTitles.map((title, index) => (
            <div className={"p-2 border-2 border-gray-200 rounded-3xl text-sm text-gray-500 m-1 font-light"} key={index}>{title}</div>
          ))}
        </div>
        <div className={"flex flex-col items-center w-full px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96 mb-20"}>
          {props?.data?.mdx?.exports?.sections?.map((section, index) => (
            <div className={"flex w-full flex-col py-12 items-center border-b-2 border-gray-100" + (section.orientation === 'right' ? ' md:flex-row-reverse' : ' md:flex-row')} key={index}>
              <img loading="lazy" src={section?.icon?.publicURL} alt={section.alt} className={"w-full md:w-1/3"} />
              <div className={"flex flex-col w-full md:w-2/3 mx-5"}>
                <h2 className={"text-lg md:text-xl lg:text-2xl text-primary-default text-center pb-5 font-light mt-10 md:mt-0"}>{section.title}</h2>
                {section.descriptions.map((description, index) => (
                  <div key={index} className={"flex flex-col text-sm"}>
                    <p className={"mb-2 text-gray-500"}>{description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <RelatedFeatures language={props?.pageContext?.language} categories={props?.data?.mdx?.exports?.categories} />
      </div>
    </SiteLayout>
  );
}

export const query = graphql`query FeatureQuery($slug: String!) {
  mdx(fields: {slug: {eq: $slug}}) {
    body
    fields {
      slug
      canonical
      lang
      relatedPages {
        en {
            url
            country
          }
        it {
            url
            country
          }
        ro {
            url
            country
        }
        es {
            url
            country
        }
        fr {
            url
            country
        }
        cz {
            url
            country
        }
        de {
            url
            country
        }
        pl {
            url
            country
        }
      }
    }
    exports {
      categories {
        name
        index
      }
      sections {
        orientation
        title
        icon {
          publicURL
        }
        alt
        descriptions
      }
      carouselImages {
        name {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              tracedSVGOptions: {color: "#00ab6b"}
              transformOptions: {cropFocus: ENTROPY}
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
        alt
      }
    }
    frontmatter {
      type
      title
      carouselTitle
      carouselDescription
      chipTitles
      cover {
        publicURL
      }
      coverAlt
      path
      metaTitle
      metaDescription
      metaImage {
        publicURL
      }
      metaCover {
        publicURL
      }
      metaKeywords
      twitterCard
      ldJson
    }
  }
}
`
